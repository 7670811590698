import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import format from "date-fns/format";
import addHours from "date-fns/addHours";
import addMinutes from "date-fns/addMinutes";

// hooks and cart
import { cartContext } from "providers/CartProvider";

import Layout from "components/Layout";
import SEO from "components/Seo";
import ScheduleItem from "components/ScheduleItem";

import {
  MonthlyScheduleGroup,
  MonthlyScheduleList,
  MonthlyScheduleHeader,
} from "styles/styles";

const AllSchedulesPage = () => {
  const [scheduleData, setScheduleData] = useState([]);
  const siteContext = useContext(cartContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/courses/schedules`,
          {
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        // console.log("Main -> response.data", response.data);
        setScheduleData(response.data);
      } catch (e) {
        siteContext.makeToast("Unable to fetch schedule data.", "error");
      }
    }

    fetchData();
  }, []);

  const pgClasses = scheduleData;

  const formattedClasses =
    pgClasses && pgClasses.length > 0
      ? pgClasses
          .map(cl => {
            // const { date, datestop, timestart, timeend, id__normalized } = cat;
            const { date } = cl;
            const rawDate = Date.parse(date);
            // if (rawDate < Date.now()) {
            //   return null;
            // }
            const [startHours, startMinutes] = cl.timestart.split(":");
            let adjustedStartDate = addHours(rawDate, startHours);
            adjustedStartDate = addMinutes(adjustedStartDate, startMinutes);

            // this prevents classes from being listed past the time
            // (but doesn't prevent if added to cart already!)
            if (adjustedStartDate < Date.now()) {
              // console.log("too late, buddy.");
              return null;
            }

            // remove private classes
            if (cl.coursecats.find(c => c.slug === "private")) return null;

            const year = format(adjustedStartDate, "yyyy");
            const month = format(adjustedStartDate, "MMM");
            const day = format(adjustedStartDate, "dd");

            return {
              year,
              month,
              day,
              date: adjustedStartDate,
              ...cl,
            };
          })
          .filter(x => x)
          .sort((a, b) => b.date > a.date)
      : [];

  // const curYear = new Date().getFullYear();
  // const years = [curYear - 1, curYear, curYear + 1];

  const classesByMonth = formattedClasses.reduce((acc, curr) => {
    const theMonthNum = format(Date.parse(curr.date), "M");
    const theYear = curr.year;

    const matchItem = acc.find(
      x => x.year === theYear && x.monthNum === theMonthNum
    );

    // new month/year
    if (!matchItem) {
      const tempMonthObj = {
        monthNum: theMonthNum,
        monthAbbrev: curr.month,
        year: theYear,
        classes: [curr],
      };

      return acc.concat(tempMonthObj);
    }

    // otherwise, just add to an existing month/year item
    const newClasses = matchItem.classes.concat(curr);
    matchItem.classes = newClasses;

    return acc;
  }, []);

  const cartState = useContext(cartContext);

  const addToCart = (scheduleID, qty = 1) => {
    const attendeesArr = [];
    for (let z = 0; z < qty; z++) {
      attendeesArr.push({
        name: "",
        email: "",
      });
    }

    const newItem = {
      id: scheduleID,
      attendees: attendeesArr,
      qty,
    };

    cartState.updateProducts(newItem);
  };

  return (
    <Layout pageInfo={{ pageName: "all-classes" }}>
      <SEO title="All Upcoming Classes" />
      {/* <Banner title="Find Us" /> */}
      <section className="class-main-details">
        <Container>
          <Row>
            <Col>
              <h2>All Currently Scheduled Classes</h2>

              <p>
                Below you'll find our full list of currently scheduled classes.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bluedark">
        <Container>
          <Row>
            <Col>
              <div className="schedule">
                <h3>Complete Schedule:</h3>

                <p style={{ fontStyle: "italic" }}>
                  Note: This list does NOT include requalifications.
                </p>

                {classesByMonth &&
                  classesByMonth.map((monthData, i) => {
                    if (monthData.classes.length > 0) {
                      return (
                        <MonthlyScheduleGroup key={`month-${i}`}>
                          <MonthlyScheduleHeader>
                            <h4 className="subhead">
                              {`${monthData.monthAbbrev} ${monthData.year}`}
                            </h4>
                          </MonthlyScheduleHeader>

                          <MonthlyScheduleList>
                            {monthData.classes.length > 0 ? (
                              monthData.classes.map(item => (
                                <li key={`${item.date}-${item.title}`}>
                                  <ScheduleItem
                                    item={item}
                                    addToCart={addToCart}
                                    cartState={cartState}
                                  />
                                </li>
                              ))
                            ) : (
                              <p>No classes currently scheduled.</p>
                            )}
                          </MonthlyScheduleList>
                        </MonthlyScheduleGroup>
                      );
                    }
                    return null;
                  })}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default AllSchedulesPage;

// export const query = graphql`
//   query {
//     allMyschedules {
//       nodes {
//         id__normalized
//         title
//         totalseats
//         date
//         timestart
//         datestop
//         price
//         online
//         note
//         coursecats {
//           slug
//           _pivot_coursecat_id
//         }
//         orders {
//           qty
//           schedule_id
//         }
//       }
//     }
//   }
// `;
